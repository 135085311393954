import React from 'react';
import SlideShow from '../common/widgets/SideShow';
import settings from '../../settings/settings';

export default function HeroHeader({
	images,
	freeze = false,
	title,
	time = 5,
	transitionTime = 2,
	heroHeaderCss = 'hero-header',
	opacity = 1,
	sideShowEdgeImage = settings.assetsFolder + 'images/slideShowEdge_02.svg',
	backgroundColor = '#ccc',
}) {
	const slideShowFrameClass = {
		position: 'relative',
		height: '300px',
		width: '100%',
		overflow: 'Hidden',
		backgroundColor: backgroundColor,
	};

	const headerHolderClass = {
		position: 'absolute',
		zIndex: 2,
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		color: 'white',
	};
	const sideShowEdgeClass = {
		position: 'absolute',
		zIndex: 1,
		width: '100%',
		height: '100%',
		backgroundImage: `url(${sideShowEdgeImage})`,
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
	};

	return (
		<>
			<div style={slideShowFrameClass}>
				<SlideShow freeze={freeze} images={images} time={time} transitionTime={transitionTime} opacity={opacity} />
				<div style={sideShowEdgeClass}></div>
				<div style={headerHolderClass}>
					<h1 className={heroHeaderCss}> {title}</h1>
				</div>
			</div>
		</>
	);
}
