import React, { useRef, useState } from 'react';
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import emailjs from '@emailjs/browser';
import { AnimatePresence, motion } from 'framer-motion';

export default function ContactForm() {
	const form = useRef();
	const [formSent, setFormSent] = useState(false);
	const [formSending, setFormSending] = useState(false);

	const sendEmail = e => {
		e.preventDefault();
		console.log(form.current);
		setFormSending(true);

		emailjs.sendForm('contact_service', 'contact_form', form.current, 'user_NZYvx90KOAuMTaROaZ8lJ').then(
			result => {
				console.log(result.text);
				setFormSent(true);
				setFormSending(false);
			},
			error => {
				console.log(error.text);
				setFormSending(false);
			}
		);
	};

	return (
		<AnimatePresence exitBeforeEnter initial={false}>
			{!formSent && (
				<motion.div
					key={1}
					initial={{ x: 0, opacity: 1 }}
					animate={{ x: 0, opacity: 1 }}
					exit={{ x: 70, opacity: 0 }}
					transition={{ duration: 0.2 }}
				>
					<Box
						component='form'
						autoComplete='on'
						sx={{
							'& .MuiTextField-root': { my: 1, width: '100%' },
							'& .MuiFormControl-root': { my: 1, width: '100%' },
							'& .MuiButton-root': { my: 1 },
						}}
						ref={form}
						onSubmit={sendEmail}
					>
						<TextField id='outlined-basic' type='text' name='user_name' label='Name' variant='outlined' />
						<TextField id='outlined-basic' type='email' name='user_email' label='Email Address' variant='outlined' />
						<TextField
							id='outlined-basic'
							inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
							name='user_phone'
							label='Phone Number'
							variant='outlined'
						/>
						<FormControl>
							<FormLabel id='demo-radio-buttons-group-label'>Enquiry Type</FormLabel>
							<RadioGroup
								aria-labelledby='demo-radio-buttons-group-label'
								row
								defaultValue='General'
								name='enquiry_type'
							>
								<FormControlLabel labelPlacement='bottom' value='General' control={<Radio />} label='General' />
								<FormControlLabel labelPlacement='bottom' value='Sales' control={<Radio />} label='Sales' />
								<FormControlLabel labelPlacement='bottom' value='Support' control={<Radio />} label='Support' />
								<FormControlLabel labelPlacement='bottom' value='Careers' control={<Radio />} label='Careers' />
							</RadioGroup>
						</FormControl>
						<TextField
							id='outlined-multiline-flexible'
							maxRows={15}
							minRows={3}
							multiline
							type='email'
							name='message'
							label='Message'
							variant='outlined'
						/>
						<br />
						<LoadingButton
							type='submit'
							variant='contained'
							loading={formSending}
							loadingPosition='end'
							endIcon={<SendIcon />}
						>
							Send
						</LoadingButton>
					</Box>
				</motion.div>
			)}
			{formSent && (
				<motion.div
					key={2}
					initial={{ y: -70, opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					exit={{ y: -70, opacity: 0 }}
					transition={{ duration: 0.25 }}
				>
					<Box>
						<p>Your message has been sent.</p>
					</Box>
				</motion.div>
			)}
		</AnimatePresence>
	);
}
