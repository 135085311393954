import React from 'react';
import settings from '../../../settings/settings';

export default function GraphicList({ items, spacing = 1 }) {
	return (
		<>
			{items.map(item => (
				<div key={item._id} className='d-flex flex-row align-items-center'>
					<div className={'p-' + spacing}>
						<img
							width={item.icon.size.w}
							height={item.icon.size.h}
							src={settings.assetsFolder + item.icon.ref}
							alt='icon'
						></img>
					</div>
					<div className='p-1 align-self-center'>{item.text}</div>
				</div>
			))}
		</>
	);
}
