import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Home from './components/pages/Home';
import About from './components/pages/About';
import Capability from './components/pages/Capability';
import Contact from './components/pages/Contact';
import Projects from './components/pages/Projects';
import { AnimatePresence } from 'framer-motion';
import NavBar from './components/layout/NavBar';
import Project from './components/pages/Project';

function App() {
	const location = useLocation();
	return (
		<>
			<AnimatePresence>{location.pathname !== '/' && <NavBar />}</AnimatePresence>

			<AnimatePresence exitBeforeEnter>
				<Routes location={location} key={location.pathname}>
					<Route path='/' element={<Home />} />
					<Route path='/about' element={<About />} />
					<Route path='/capability' element={<Capability />} />
					<Route path='/projects' element={<Projects />} />
					<Route path='/projects/:projectId' element={<Project />} />

					<Route path='/contact' element={<Contact />} />
					<Route path='*' element={<Home />} />
				</Routes>
			</AnimatePresence>
		</>
	);
}

export default App;
