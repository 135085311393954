import { Box, Grid } from '@mui/material';
import React from 'react';
import CardPage from './CardPage';
import CardSample from './CardSample';
import CardVideo from './CardVideo';

export default function CardLayout({ cardLayout }) {
	return (
		<Box className='mt-5'>
			<Grid container rowSpacing={{ sm: 4, xs: 2 }} columnSpacing={{ sm: 4, xs: 2 }}>
				{cardLayout.cards.map(card => (
					<Grid key={card._id} item xs={cardLayout.settings.xs} sm={cardLayout.settings.sm} md={cardLayout.settings.md}>
						{card.project ? (
							<CardPage cardData={card._id} />
						) : card.courseSample ? (
							<CardSample cardData={card.courseSample} />
						) : card.video ? (
							<CardVideo cardData={card.video} />
						) : null}
					</Grid>
				))}
			</Grid>
		</Box>
	);
}
