import React, { useState, useEffect } from 'react';
import { Box, Card, CardActionArea, CardActions, CardContent, CardMedia, Chip } from '@mui/material';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import settings from '../../settings/settings';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import TheatersIcon from '@mui/icons-material/Theaters';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import theme from '../../settings/theme';
import { ThemeProvider } from '@emotion/react';

const styles = {
	overlay: {
		position: 'absolute',
		top: '135px',
		left: '10px',
	},
};

export default function CardPage({ cardData }) {
	const [pageData, setPageData] = useState(null);
	const [videoIcon, setVideoIcon] = useState(false);
	const [sampleIcon, setSampleIcon] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		async function getData(data) {
			try {
				const res = await fetch(settings.apisLocationProjects + data + '.json');
				const json = await res.json();
				// console.log(json);
				setPageData(json);
				detectMediaTypes(json);
			} catch (err) {
				console.log(err);
			}
		}
		function detectMediaTypes(json) {
			// find video

			if (json.sections && !videoIcon) {
				for (let i = 0; i < json.sections.length; i++) {
					// console.log('find video in ', json.sections[i]);
					if ('cardLayout' in json.sections[i]) {
						if (json.sections[i].cardLayout.cards) {
							for (let o = 0; o < json.sections[i].cardLayout.cards.length; o++) {
								if ('video' in json.sections[i].cardLayout.cards[o]) {
									setVideoIcon(true);
								}
							}
						}
					}
				}
			}

			// find sample

			if (json.sections && !sampleIcon) {
				for (let i = 0; i < json.sections.length; i++) {
					// console.log('find sample in ', json.sections[i]);
					if ('cardLayout' in json.sections[i]) {
						if (json.sections[i].cardLayout.cards) {
							for (let o = 0; o < json.sections[i].cardLayout.cards.length; o++) {
								if ('courseSample' in json.sections[i].cardLayout.cards[o]) {
									setSampleIcon(true);
								}
							}
						}
					}
				}
			}
		}
		getData(cardData);
	}, [cardData, videoIcon, sampleIcon]);

	function shortenText(val) {
		const shortenedText = `${_.truncate(val, {
			length: 100,
			separator: ' ',
		})} <spam style='color:#d62300;font-weight:bold'>Click for more.<span>`;
		return shortenedText;
	}

	function handleClick() {
		navigate(cardData);
	}

	return (
		<>
			{pageData && (
				<ThemeProvider theme={theme}>
					<Card sx={{ maxWidth: 345, height: '100%' }}>
						<CardActionArea onClick={handleClick} sx={{ height: '100%' }}>
							<Box sx={{ height: '100%' }}>
								<CardMedia
									component='img'
									height='180'
									image={settings.assetsFolder + pageData.img || settings.defaultCardImage}
									alt='hero'
								/>
								<Chip style={styles.overlay} label={'Project Examples'} color='primary' icon={<AccountTreeIcon />} />
								<CardContent sx={{ marginBottom: '20px' }}>
									<h3 className='card-title'>{pageData.title || 'Project Title'}</h3>
									{pageData.sections[0].columns.content[0].text.content && (
										<div
											className='card-body'
											dangerouslySetInnerHTML={{
												__html: shortenText(pageData.sections[0].columns.content[0].text.content),
											}}
										></div>
									)}
								</CardContent>
								<CardActions sx={{ position: 'absolute', bottom: 0 }}>
									{videoIcon && <TheatersIcon color='secondary' />}

									{sampleIcon && <SmartDisplayIcon color='secondary' />}
								</CardActions>
							</Box>
						</CardActionArea>
					</Card>
				</ThemeProvider>
			)}
		</>
	);
}
