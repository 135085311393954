import React, { useRef, useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import settings from '../../../settings/settings';
import './slideShow.css';

export default function SlideShow({ freeze = false, time = 5, transitionTime = 2, opacity = 1, images }) {
	const imageIndex = useRef(0);
	const [bgImage, setImage] = useState(images[imageIndex.current]);

	useEffect(() => {
		if (!freeze) {
			const interval = setInterval(() => {
				// load new image

				if (imageIndex.current < images.length - 1) {
					imageIndex.current++;
				} else {
					imageIndex.current = 0;
				}
				setImage(images[imageIndex.current]);
				// console.log(imageIndex.current, bgImage);
			}, time * 1000);
			return () => {
				if (interval) {
					clearInterval(interval);
				}
			};
		}
	});
	return (
		<AnimatePresence>
			<motion.div
				className='bg-image-container'
				key={bgImage._id}
				initial={{ opacity: 0 }}
				animate={{ opacity: opacity, scale: 1.2 }}
				exit={{ opacity: 0 }}
				style={{ backgroundImage: `url(${settings.assetsFolder + bgImage.img})` }}
				transition={{ opacity: { duration: transitionTime }, scale: { duration: time + 2 } }}
			></motion.div>
		</AnimatePresence>
	);
}
