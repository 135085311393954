export const contactHeroImages = [
  {
    _id: 1,
    img: 'images/slideShow/home_02.jpg',
  },
  {
    _id: 2,
    img: 'images/slideShow/D61_3759_bw.jpg',
  },
  {
    _id: 3,
    img: 'images/slideShow/blender_01-1000.jpg',
  },
  {
    _id: 4,
    img: 'images/slideShow/D61_0873_bw.jpg',
  },
  {
    _id: 5,
    img: 'images/slideShow/D61_0540_bw.jpg',
  },
];
