import { Container } from '@mui/material';
import React from 'react';
import MasonryImageList from '../common/media/MasonryImageList';
import TitleArea from '../layout/TitleArea';
import Columns from './Columns';
import '../../App.css';
import CardLayout from './CardLayout';

export default function Sections({ sections }) {
	return (
		<Container maxWidth='lg' sx={{ px: [2, 6] }}>
			{sections.map(section => {
				return (
					<div key={section._id}>
						{section.title ? (
							<TitleArea text={section.title.text} />
						) : section.columns ? (
							<Columns columns={section.columns} />
						) : section.divider ? (
							<MasonryImageList imageList={section.divider.images} />
						) : section.cardLayout ? (
							<CardLayout cardLayout={section.cardLayout} />
						) : null}
					</div>
				);
			})}
		</Container>
	);
}
