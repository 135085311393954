import { Box, Grid } from '@mui/material';
import React from 'react';
import GraphicList from '../common/widgets/GraphicList';
import ColumnCard from './ColumnCard';

export default function Columns({ columns }) {
	return (
		<Box className='mt-5'>
			<Grid container rowSpacing={{ sm: 0, xs: 0 }} columnSpacing={{ md: 6, xs: 3 }}>
				{columns.content.map(column => (
					<Grid key={column._id} item xs={columns.settings.xs} sm={columns.settings.sm} md={columns.settings.md}>
						{column.text ? (
							<div className='column-body' dangerouslySetInnerHTML={{ __html: column.text.content }}></div>
						) : column.image ? (
							<img width='100%' src={column.image.ref} alt={column.image.alt} />
						) : column.graphicList ? (
							<GraphicList items={column.graphicList.items} spacing={column.graphicList.settings?.spacing} />
						) : column.card ? (
							<ColumnCard cardData={column.card} />
						) : null}
					</Grid>
				))}
			</Grid>
		</Box>
	);
}
