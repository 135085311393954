import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { pageAnimation1, pageTransition25 } from '../common/animations/pageTransitions';
import { homePageImages } from '../../api/homePageImages';
import HeroHeader from '../layout/HeroHeader';
import Sections from '../layout/Sections';
import Footer from '../layout/Footer';
import settings from '../../settings/settings';

export default function Capability() {
	const [pageData, setPageData] = useState(null);

	useEffect(() => {
		const url = settings.apisLocation + 'capabilityPageData.json';
		const getData = async val => {
			try {
				const res = await fetch(val);
				const json = await res.json();
				setPageData(json);
			} catch (err) {
				console.log(err);
			}
		};
		getData(url);
	}, []);

	return (
		<>
			{pageData && (
				<motion.div initial='in' animate='end' exit='out' variants={pageAnimation1} transition={pageTransition25}>
					<div id='page-container'>
						<div id='page-inner'>
							<HeroHeader
								images={pageData.hero?.images || homePageImages}
								title={pageData.hero.text}
								time={10}
								transitionTime={3}
								opacity={0.4}
								backgroundColor={pageData.hero?.backgroundColor || '#ccc'}
							/>
							<Sections sections={pageData.sections} />
						</div>
						<Footer />
					</div>
				</motion.div>
			)}
		</>
	);
}
