import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { pageAnimation1, pageTransition25 } from '../common/animations/pageTransitions';
import HeroHeader from '../layout/HeroHeader';
import { homePageImages } from '../../api/homePageImages';
import Sections from '../layout/Sections';
import Footer from '../layout/Footer';
import { useParams } from 'react-router-dom';
import settings from '../../settings/settings';

export default function Project() {
	const urlFolder = settings.apisLocation + 'projects/';
	const urlParams = useParams();
	const url = urlFolder + urlParams.projectId + '.json';

	const [pageData, setPageData] = useState(null);

	async function getData(val) {
		try {
			const res = await fetch(val);
			const json = await res.json();
			// console.log(json);
			setPageData(json);
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		getData(url);
	}, [url]);

	return (
		<>
			{pageData ? (
				<motion.div
					initial='in'
					animate='end'
					exit='out'
					variants={pageAnimation1}
					transition={pageTransition25}
				>
					<div id='page-container'>
						<div id='page-inner'>
							<HeroHeader
								images={pageData.hero?.images || homePageImages}
								title={pageData.title}
								time={10}
								transitionTime={3}
								opacity={0.2}
								heroHeaderCss='hero-header-project'
								backgroundColor={pageData.hero?.backgroundColor || '#ccc'}
							/>
							<Sections sections={pageData.sections} />
						</div>
						<Footer />
					</div>
				</motion.div>
			) : null}
		</>
	);
}
