import { createTheme } from '@mui/material/styles';

const theme = createTheme({
	palette: {
		primary: {
			main: '#0075C9',
		},
		secondary: {
			main: '#75797B',
		},
		white: {
			main: '#ffffff',
		},
		blueLight: {
			main: '#4cb2fc',
		},
		blueDark: {
			main: '#00487c',
		},
		greenLight: {
			main: '#8fd60b',
		},
		greenDark: {
			main: '#59a81a',
		},
		orange: {
			main: '#c95b00',
		},
		red: {
			main: '#d62300',
		},
		yellow: {
			main: '#fcc749',
		},
	},
});

export default theme;
