import React, { useState } from 'react';
// import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogTitle from '@mui/material/DialogTitle';
import theme from '../../../settings/theme';
import Vimeo from '@u-wave/react-vimeo';
import { CircularProgress } from '@mui/material';
import { ThemeProvider } from '@emotion/react';

export default function VimeoModal({ open, modalData, close }) {
	const [vimeoLoaded, setVimeoLoaded] = useState(false);

	function handleLoaded() {
		// console.log('loaded');
		setVimeoLoaded(true);
	}

	function handleClose() {
		// console.log('closed');
		setVimeoLoaded(false);
		close();
	}

	// console.log('vimeoLoaded', vimeoLoaded);
	return (
		<ThemeProvider theme={theme}>
			<Dialog
				BackdropProps={{ style: { backgroundColor: '#00487cE6' } }}
				fullWidth={true}
				maxWidth={'lg'}
				open={open}
				onClose={handleClose}
				sx={{ textAlign: 'center' }}
			>
				<div style={{ width: '100%' }}>
					{!vimeoLoaded && (
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								width: '100%',
								height: '100%',
								minHeight: '20vh',
								position: 'absolute',
								margin: 'auto',
								zIndex: 2,
							}}
						>
							<CircularProgress />
						</div>
					)}
					<div
						style={{
							position: 'relative',
							zIndex: 1,
						}}
					>
						<Vimeo video={modalData.ref} autoplay responsive={true} onReady={handleLoaded} onEnd={handleClose} />
					</div>
				</div>
			</Dialog>
		</ThemeProvider>
	);
}
