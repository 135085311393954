export const pageAnimation1 = {
	end: {
		opacity: 1,
		y: 0,
		scale: 1,
	},
	in: {
		opacity: 0,
		y: -50,
		scale: 1,
	},
	out: {
		opacity: 0,
		y: 50,
		scale: 1,
	},
};

export const pageAnimationHome = {
	end: {
		opacity: 1,
	},
	in: {
		opacity: 0,
	},
	out: {
		opacity: 0,
	},
};

export const pageTransition25 = {
	duration: 0.25,
};

export const pageTransition50 = {
	duration: 0.5,
};
