import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import huLogo from '../common/svg/hu_icon_solid.svg';
import Fab from '@mui/material/Fab';
import { homePageImages } from '../../api/homePageImages';
import style from './Home.module.css';
import { pageAnimationHome, pageTransition50 } from '../common/animations/pageTransitions';
import Copyright from '../common/widgets/Copyright';
import SlideShow from '../common/widgets/SideShow';

export default function Home() {
	return (
		<motion.div initial='in' animate='end' exit='out' variants={pageAnimationHome} transition={pageTransition50}>
			<div className='site-wrapper'>
				<SlideShow opacity={0.2} time={6} images={homePageImages} />
				<div className={style.siteWrapperInner}>
					<div className={style.coverContainer}>
						<div>
							<div className={style.inner}>
								<img height='150px' src={huLogo} alt='HU logo' />
								<h1 className='text-center'>Welcome to HU Associates</h1>
								<p className='text-center'>
									Responsible for creating award winning digital learning for the Nuclear Power Industry.
								</p>

								<h1>
									go...
									<span className='ms-3'>
										<Fab component={Link} to={'/about'} color='primary' aria-label='enter'>
											<ArrowForwardIosIcon />
										</Fab>
									</span>
								</h1>

								<p className='lead'></p>
							</div>
							<div className={style.mastfoot}>
								<div className={style.inner}>
									<Copyright size='16px' />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</motion.div>
	);
}
