import React, { useState } from 'react';
import { Card, CardActionArea, CardContent, CardMedia, Chip } from '@mui/material';
import VimeoModal from '../common/widgets/VimeoModal';
import settings from '../../settings/settings';
import TheatersIcon from '@mui/icons-material/Theaters';
import { Box } from '@mui/system';

const styles = {
	overlay: {
		position: 'absolute',
		top: '135px',
		left: '10px',
	},
};

export default function CardVideo({ cardData }) {
	const [open, setOpen] = useState(false);

	function handleClickOpen() {
		setOpen(true);
	}

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<Card sx={{ maxWidth: 345, height: '100%' }}>
				<CardActionArea onClick={handleClickOpen} sx={{ height: '100%' }}>
					<Box sx={{ height: '100%' }}>
						<CardMedia
							style={styles.media}
							component='img'
							height='180'
							image={settings.assetsFolder + cardData.img || settings.defaultCardImageVideo}
							alt='hero'
						/>
						<Chip style={styles.overlay} label={'Film Sample'} color='primary' icon={<TheatersIcon />} />

						<CardContent>
							<h3 className='card-title'>{cardData.title || 'Project Title'}</h3>
							<div className='card-body' dangerouslySetInnerHTML={{ __html: cardData.text }}></div>
						</CardContent>
					</Box>
				</CardActionArea>
			</Card>

			<VimeoModal open={open} modalData={cardData} close={handleClose} />
		</>
	);
}
