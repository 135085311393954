const settings = {
	assetsFolder: './assets/',
	apisLocation: './assets/fakeAPIS/',
	apisProjects: 'projects/',
	apisLocationProjects: './assets/fakeAPIS/projects/',
	defaultCardImage: './assets/images/D61_0434_600.jpg',
	defaultCardImageSample: './assets/images/interface_300.jpg',
	defaultCardImageVideo: './assets/images/D61_1747_600.jpg',
};

export default settings;
