import React from 'react';
import { Card, CardActionArea, CardContent, CardMedia, Chip } from '@mui/material';
import settings from '../../settings/settings';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import { Box } from '@mui/system';

const styles = {
	overlay: {
		position: 'absolute',
		top: '135px',
		left: '10px',
	},
};

export default function CardSample({ cardData }) {
	function handleClickOpen() {
		window.open(cardData.url, '_blank');
	}

	return (
		<>
			<Card sx={{ maxWidth: 345, height: '100%' }}>
				<CardActionArea onClick={handleClickOpen} sx={{ height: '100%' }}>
					<Box sx={{ height: '100%' }}>
						<CardMedia
							component='img'
							height='180'
							image={settings.assetsFolder + cardData.img || settings.defaultCardImageSample}
							alt='hero'
						/>
						<Chip style={styles.overlay} label={'Course Demo'} color='primary' icon={<SmartDisplayIcon />} />
						<CardContent>
							<h3 className='card-title'>{cardData.title || 'Project Title'}</h3>
							<div className='card-body' dangerouslySetInnerHTML={{ __html: cardData.text }}></div>
						</CardContent>
					</Box>
				</CardActionArea>
			</Card>
		</>
	);
}
