export const homePageImages = [
	{
		_id: 1,
		img: 'images/slideShow/blender_04-1000.jpg',
	},
	{
		_id: 2,
		img: 'images/slideShow/code_01-1000.jpg',
	},
	{
		_id: 3,
		img: 'images/slideShow/home_02.jpg',
	},
	{
		_id: 4,
		img: 'images/slideShow/home_03.jpg',
	},
	{
		_id: 5,
		img: 'images/slideShow/home_04.jpg',
	},
	{
		_id: 6,
		img: 'images/slideShow/home_06.jpg',
	},
	{
		_id: 7,
		img: 'images/slideShow/blender_02-1000.jpg',
	},
];
