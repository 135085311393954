import React from 'react';
import { Box, Container, Grid } from '@mui/material';
import { motion } from 'framer-motion';
import { pageAnimation1, pageTransition25 } from '../common/animations/pageTransitions';
import HeroHeader from '../layout/HeroHeader';
import Footer from '../layout/Footer';
import ContactForm from '../common/widgets/ContactForm';
import { contactHeroImages } from '../../api/contactPageImages';
import TitleArea from '../layout/TitleArea';

export default function Contact() {
	return (
		<motion.div initial='in' animate='end' exit='out' variants={pageAnimation1} transition={pageTransition25}>
			<div id='page-container'>
				<div id='page-inner'>
					<HeroHeader
						images={contactHeroImages}
						title='Contact'
						backgroundColor='#8fd60b'
						opacity={0.4}
						time={10}
						transitionTime={3}
					/>
					<Container maxWidth='lg' sx={{ px: [2, 6] }}>
						<TitleArea text='Want to talk? Fill in the form below...' />
						<Box className='mt-5'>
							<Grid container rowSpacing={{ sm: 0, sx: 0 }} columnSpacing={6}>
								<Grid item xs={12} sm={12} md={6} style={{ textAlign: 'center' }}>
									<img
										src='./assets/icons/contactIcons.svg'
										width='100%'
										style={{ padding: '0px 50px 50px 50px', maxWidth: '500px' }}
										alt=''
									/>
								</Grid>
								<Grid item xs={12} sm={12} md={6}>
									<ContactForm />
								</Grid>
							</Grid>
						</Box>
					</Container>
				</div>
				<Footer />
			</div>
		</motion.div>
	);
}
