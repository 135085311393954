import React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/material';
import settings from '../../../settings/settings';

export default function MasonryImageList({ imageList, width = '100%', height = '100%', colsLg = 6, colsSm = 3 }) {
	const itemData = imageList;

	const largeScreen = useMediaQuery('(min-width:800px)');

	return (
		<Box className='mt-5'>
			<ImageList sx={{ width: width, height: height }} cols={largeScreen ? colsLg : colsSm}>
				{itemData.map(item => (
					<ImageListItem key={item.img}>
						<img
							src={`${settings.assetsFolder + item.img}?w=164&h=164&fit=crop&auto=format`}
							alt={item.title}
							loading='lazy'
						/>
					</ImageListItem>
				))}
			</ImageList>
		</Box>
	);
}
