import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
// import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
// import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../settings/theme';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { useState } from 'react';
import huLogo from '../common/svg/hu_icon_tran_white.svg';
import { ListItemIcon } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import BarChartIcon from '@mui/icons-material/BarChart';

export default function NavBar() {
	const pages = [
		{
			label: 'ABOUT',
			link: '/about',
		},
		{
			label: 'CAPABILITY',
			link: '/capability',
		},
		{
			label: 'PROJECTS',
			link: '/projects',
		},
		{
			label: 'CONTACT',
			link: '/contact',
		},
	];
	// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
	const [anchorElNav, setAnchorElNav] = useState(null);
	const [navRoute, setNavRoute] = useState(null);
	// const [anchorElUser, setAnchorElUser] = React.useState(null);

	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		setNavRoute(location.pathname);
		// console.log(location.pathname);
	}, [location]);

	function handleOpenNavMenu(event) {
		setAnchorElNav(event.currentTarget);
	}
	// function handleOpenUserMenu(event) {
	// 	setAnchorElUser(event.currentTarget);
	// }

	function handleCloseNavMenu() {
		setAnchorElNav(null);
	}

	function handleClickNavMenu(link) {
		// console.log(link);
		navigate(link);
		setAnchorElNav(null);
	}

	// function handleCloseUserMenu() {
	// 	setAnchorElUser(null);
	// }

	return (
		<ThemeProvider theme={theme}>
			<motion.div
				className='header'
				initial={{ y: -70, opacity: 0 }}
				animate={{ y: 0, opacity: 1 }}
				exit={{ y: -70, opacity: 0 }}
				transition={{ delay: 0.1, duration: 0.25 }}
			>
				<AppBar color='primary'>
					<Container maxWidth='lg'>
						<Toolbar disableGutters sx={{ px: 1 }}>
							<Button sx={{ px: 0 }} onClick={() => handleClickNavMenu('/')}>
								<img height='35px' src={huLogo} alt='HU logo' />
							</Button>

							{/* Small screen menu */}

							<Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none' }, justifyContent: 'flex-end' }}>
								<IconButton
									size='large'
									aria-controls='menu-appbar'
									aria-haspopup='true'
									onClick={handleOpenNavMenu}
									color='inherit'
								>
									<MenuIcon />
								</IconButton>
								<Menu
									id='menu-appbar'
									anchorEl={anchorElNav}
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'left',
									}}
									keepMounted
									transformOrigin={{
										vertical: 'top',
										horizontal: 'left',
									}}
									open={Boolean(anchorElNav)}
									onClose={handleCloseNavMenu}
									sx={{
										display: { xs: 'block', sm: 'none' },
									}}
								>
									<MenuItem onClick={() => handleClickNavMenu(pages[0].link)}>
										<ListItemIcon>
											<InfoIcon color='blueLight' fontSize='large' />
										</ListItemIcon>
										<Typography sx={{ mx: 4, my: 2 }} fontSize='large' textAlign='center' color='secondary'>
											{pages[0].label}
										</Typography>
									</MenuItem>
									<MenuItem onClick={() => handleClickNavMenu(pages[1].link)}>
										<ListItemIcon>
											<BarChartIcon color='orange' fontSize='large' />
										</ListItemIcon>
										<Typography sx={{ mx: 4, my: 2 }} fontSize='large' textAlign='center' color='secondary'>
											{pages[1].label}
										</Typography>
									</MenuItem>
									<MenuItem onClick={() => handleClickNavMenu(pages[2].link)}>
										<ListItemIcon>
											<AccountTreeIcon color='yellow' fontSize='large' />
										</ListItemIcon>
										<Typography sx={{ mx: 4, my: 2 }} fontSize='large' textAlign='center' color='secondary'>
											{pages[2].label}
										</Typography>
									</MenuItem>
									<MenuItem onClick={() => handleClickNavMenu(pages[3].link)}>
										<ListItemIcon>
											<AlternateEmailIcon color='greenLight' fontSize='large' />
										</ListItemIcon>
										<Typography sx={{ mx: 4, my: 2 }} fontSize='large' textAlign='center' color='secondary'>
											{pages[3].label}
										</Typography>
									</MenuItem>
								</Menu>
							</Box>
							<Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' }, justifyContent: 'flex-end' }}>
								<Button
									startIcon={<InfoIcon color='blueLight' />}
									onClick={() => handleClickNavMenu(pages[0].link)}
									sx={{ my: 1 }}
									color='white'
									variant={navRoute === pages[0].link ? 'outlined' : null}
								>
									{pages[0].label}
								</Button>
								<Button
									startIcon={<BarChartIcon color='orange' />}
									onClick={() => handleClickNavMenu(pages[1].link)}
									sx={{ my: 1 }}
									color='white'
									variant={navRoute === pages[1].link ? 'outlined' : null}
								>
									{pages[1].label}
								</Button>
								<Button
									startIcon={<AccountTreeIcon color='yellow' />}
									onClick={() => handleClickNavMenu(pages[2].link)}
									sx={{ my: 1 }}
									color='white'
									variant={navRoute === pages[2].link ? 'outlined' : null}
								>
									{pages[2].label}
								</Button>
								<Button
									startIcon={<AlternateEmailIcon color='greenLight' />}
									onClick={() => handleClickNavMenu(pages[3].link)}
									sx={{ my: 1 }}
									color='white'
									variant={navRoute === pages[3].link ? 'outlined' : null}
								>
									{pages[3].label}
								</Button>
							</Box>

							{/* <Box sx={{ flexGrow: 0 }}>
								<Tooltip title='Open settings'>
									<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
										<Avatar alt='Remy Sharp' src='/static/images/avatar/2.jpg' />
									</IconButton>
								</Tooltip>
								<Menu
									sx={{ mt: '45px' }}
									id='menu-appbar'
									anchorEl={anchorElUser}
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'right',
									}}
									keepMounted
									transformOrigin={{
										vertical: 'top',
										horizontal: 'right',
									}}
									open={Boolean(anchorElUser)}
									onClose={handleCloseUserMenu}
								>
									{settings.map(setting => (
										<MenuItem key={setting} onClick={handleCloseNavMenu}>
											<Typography textAlign='center'>{setting}</Typography>
										</MenuItem>
									))}
								</Menu>
							</Box> */}
						</Toolbar>
					</Container>
				</AppBar>
			</motion.div>
		</ThemeProvider>
	);
}
