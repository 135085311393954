import { Card, CardContent, CardMedia } from '@mui/material';
import settings from '../../settings/settings';
import React from 'react';
import { Box } from '@mui/system';

export default function ColumnCard({ cardData }) {
	// console.log(cardData.title);
	return (
		<Box sx={{ maxWidth: 345, height: '100%', paddingBottom: { md: '48px', xs: '24px' } }}>
			<Card sx={{ maxWidth: 345, height: '100%', marginBottom: '0px', paddingBottom: '0px' }}>
				{cardData.image && (
					<CardMedia
						component='img'
						height='140'
						image={settings.assetsFolder + cardData.image.ref}
						alt={cardData.image.alt}
					/>
				)}
				<CardContent sx={{ backgroundColor: '#0075c9', paddingBottom: '10px' }}>
					{cardData.title && <h3 className='card-title-white'>{cardData.title}</h3>}
				</CardContent>
				<CardContent sx={{ marginBottom: '0px' }}>
					{cardData.text && (
						<div
							className='card-body'
							dangerouslySetInnerHTML={{
								__html: cardData.text,
							}}
						></div>
					)}
				</CardContent>
			</Card>
		</Box>
	);
}
