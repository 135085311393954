import { Container, Grid, IconButton } from '@mui/material';
import React from 'react';
import Copyright from '../common/widgets/Copyright';
import Spacer from './Spacer';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import huLogo from '../common/svg/hu_icon_solid.svg';

export default function Footer() {
	const facebook = 'https://www.facebook.com/HU-Associates-736229253124823';
	const linkedIn = 'https://www.linkedin.com/company/hu-associates';
	const twitter = 'https://twitter.com/HuAssociates';

	function handleClick(link) {
		if (link === 'facebook') {
			window.open(facebook, '_blank');
		} else if (link === 'linkedIn') {
			window.open(linkedIn, '_blank');
		} else if (link === 'twitter') {
			window.open(twitter, '_blank');
		}
	}

	return (
		<>
			<Spacer />
			<footer id='footer'>
				<Container maxWidth='lg' sx={{ px: [2, 6] }}>
					<Grid container>
						<Grid item xs={12} sm={6}>
							<div className='footer-item-holder-responsive'>
								<img height='50px' src={huLogo} alt='HU logo' />
								<h5 className='pt-2'>info@hu-associates.com</h5>
							</div>
						</Grid>
						<Grid item xs={12} sm={6}>
							<div className='footer-item-holder d-flex justify-content-evenly align-items-center'>
								<IconButton
									onClick={() => {
										handleClick('facebook');
									}}
									aria-label='Facebook'
								>
									<FacebookIcon sx={{ fontSize: 50, color: '#4267B2' }} />
								</IconButton>
								<IconButton
									onClick={() => {
										handleClick('linkedIn');
									}}
									aria-label='LinkedIn'
								>
									<LinkedInIcon sx={{ fontSize: 50, color: '#0072b1' }} />
								</IconButton>
								<IconButton
									onClick={() => {
										handleClick('twitter');
									}}
									aria-label='Twitter'
								>
									<TwitterIcon sx={{ fontSize: 50, color: '#00acee' }} />
								</IconButton>
							</div>
						</Grid>
						<Grid item xs={12}>
							<div className='pt-5 footer-item-holder d-flex flex-column text-center'>
								<Copyright />
							</div>
						</Grid>
					</Grid>
				</Container>
			</footer>
		</>
	);
}
